import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useStore } from 'effector-react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { useOutsideClick } from 'rooks';

import { animations } from '../../constants/animations';
import { LEFT_MENU } from '../../constants/routes';
import { useBoolean, UseBooleanReturn } from '../../hooks/use-boolean';
import { logout } from '../../store/auth';
import { $profile } from '../../store/profile';
import { AvatarIcon } from '../avatar-icon/avatar-icon';
import { CustomButton } from '../custom-button/custom-button';
import { CustomIcon } from '../custom-icon/custom-icon';
import { PermissionWrap } from '../permission-wrap/permission-wrap';
import { MainMenuNavLink } from './elems/main-menu-nav-link/main-menu-nav-link';

import logo from '../../static/images/logo-ad.png';

function getDefaultCollapseValue() {
  if (window.innerWidth < 1024) {
    return false;
  }

  return JSON.parse(localStorage.getItem('aside-menu-collapsed') ?? 'false');
}

export const MainMenu = () => {
  const ref = useRef<HTMLDivElement>(null);
  const asideContentRef = useRef<HTMLDivElement>(null);
  const menuCollapseControl = useBoolean(getDefaultCollapseValue());
  const popupControl = useBoolean(false);
  const [activeAccordionItemId, setActiveAccordionItemId] = useState<string | null>(null);

  const { pathname } = useLocation();
  const profile = useStore($profile);

  const isVisibleInvoiceLink = (() => {
    if (!profile || !profile.roles || !profile.roles.length) return false;

    for (const { role } of profile.roles) {
      if (new Set(['manager', 'personalManager']).has(role)) {
        return true;
      }
    }

    return false;
  })();

  const toggleMenu = () => {
    const root = document.documentElement;

    if (menuCollapseControl.value) {
      root.style.setProperty('--content-space', 'calc(var(--menu-width) + 1.5rem)');
    } else {
      root.style.setProperty('--content-space', '4.25rem');
    }

    if (!menuCollapseControl.value) {
      setActiveAccordionItemId(null);
    }
    popupControl.setFalse();
    menuCollapseControl.toggle();

    const storageCollapseValue = localStorage.getItem('aside-menu-collapsed') ?? 'false';

    localStorage.setItem('aside-menu-collapsed', JSON.stringify(!JSON.parse(storageCollapseValue)));
  };

  const hideFloatMenu = () => {
    if (!ref.current || window.innerWidth > 1280) return;

    const root = document.documentElement;

    root.classList.remove('overflow-hidden');

    ref.current.classList.remove('_float-visible');
  };

  const showFloatMenu = () => {
    const root = document.documentElement;

    if (!ref.current || !root) return;

    root.classList.add('overflow-hidden');
    ref.current.classList.add('_float-visible');
  };

  const showMenu = () => {
    const root = document.documentElement;

    root.style.setProperty('--content-space', 'calc(var(--menu-width) + 1.5rem)');

    menuCollapseControl.setFalse();
    localStorage.setItem('aside-menu-collapsed', 'false');
  };

  // const hideMenu = () => {
  //   const root = document.documentElement;
  //
  //   root.style.setProperty('--content-space', '4.25rem');
  //
  //   menuCollapseControl.setTrue();
  //   localStorage.setItem('aside-menu-collapsed', 'true');
  // };

  const onChangeActiveItem = (id: string) => {
    setActiveAccordionItemId(activeAccordionItemId === id ? null : id);
  };

  useOutsideClick(asideContentRef, hideFloatMenu);

  useEffect(() => {
    hideFloatMenu();
    popupControl.setFalse();
  }, [pathname]);

  useLayoutEffect(() => {
    const windowWidth = window.innerWidth;

    if (windowWidth < 1280) return;

    const isCollapsedLS = JSON.parse(localStorage.getItem('aside-menu-collapsed') ?? 'false');

    if (isCollapsedLS) {
      const root = document.documentElement;

      root.style.setProperty('--content-space', '4.25rem');
    }
  }, []);

  useEffect(() => {
    function handler() {
      const windowWidth = window.innerWidth;

      if (windowWidth < 1280) {
        showMenu();
      }
    }

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  const CreateInvoiceLink = ({ className }: { className: string }) => (
    <Link className={cn('w-full', className)} to='invoice/create'>
      <CustomButton className='h-[60px] w-full rounded-3xl px-3 text-sm text-white shadow-md'>
        <motion.span
          animate={{
            translateX: menuCollapseControl.value ? '158px' : '0',
          }}
          transition={{
            duration: 0.01,
          }}
        >
          <CustomIcon className='size-5' name='plus-union' />
        </motion.span>
        <span
          className={cn('invisible text-xl font-medium opacity-0 transition-all duration-300', {
            '!visible ml-3 !opacity-100': !menuCollapseControl.value,
          })}
        >
          Создать счёт
        </span>
      </CustomButton>
    </Link>
  );

  return (
    <Fragment>
      <div className='aside-menu-float-trigger' onClick={showFloatMenu}>
        <CustomIcon height={24} name='aside-burger' width={30} />
      </div>

      <div ref={ref} className={cn('aside-menu', { _collapsed: menuCollapseControl.value })}>
        <div
          ref={asideContentRef}
          className='aside-menu_content flex w-menu flex-col gap-6 transition duration-300 max-xl:flex-col-reverse'
        >
          {isVisibleInvoiceLink && <CreateInvoiceLink className='xl:hidden' />}

          <div className='space-y-4 rounded-3xl bg-dark px-3 py-6 text-sm text-white shadow-md max-xl:max-h-96 max-xl:overflow-y-hidden'>
            <div className='px-3 flex-between'>
              <Link className='' to='/'>
                <img alt='logo' className='aspect-[4/3] max-w-[40px] object-cover' src={logo} />
              </Link>

              <button className='aside-menu_collapse-button max-xl:hidden' onClick={toggleMenu}>
                <CustomIcon height={14} name='anchor-circle' width={8} />
              </button>

              <button className='aside-menu_collapse-button xl:hidden' onClick={hideFloatMenu}>
                <CustomIcon height={12} name='xmark' width={12} />
              </button>
            </div>

            <nav>
              {LEFT_MENU?.map((i) => (
                <MainMenuNavLink
                  key={i.path}
                  activeAccordionItemId={activeAccordionItemId}
                  hideMenu={showMenu}
                  menuCollapseControl={menuCollapseControl}
                  onChangeActiveItem={onChangeActiveItem}
                  {...i}
                />
              ))}
            </nav>
          </div>

          {isVisibleInvoiceLink && <CreateInvoiceLink className='max-xl:hidden' />}

          <MenuProfileCard
            mainContentVisible={!menuCollapseControl.value}
            popupControl={popupControl}
            onPopupVisibleChange={(isVisible) => {
              isVisible && showMenu();
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export const MenuProfileCard = ({
  popupControl,
  mainContentVisible,
  onPopupVisibleChange,
}: {
  mainContentVisible: boolean;
  onPopupVisibleChange: (visible: boolean) => void;
  popupControl: UseBooleanReturn;
}) => {
  const profile = useStore($profile);

  const handleLogout = () => logout();

  return (
    <div className='aside-menu-profile-popup relative rounded-3xl bg-white text-sm text-black shadow-md'>
      <div className='relative flex items-center gap-5 p-3'>
        <Link className='relative rounded-md size-5 flex-center hover:bg-bg' to='/notifications'>
          <CustomIcon className='size-5' name='rr-bell' />
          {/*<CustomBadge className='absolute -top-1 -right-1'>2</CustomBadge>*/}
        </Link>

        <button
          className={cn('relative flex grow items-center gap-2.5 rounded-xl pr-3', {
            'hover:bg-bg': mainContentVisible,
          })}
          onClick={() => {
            popupControl.toggle();
            onPopupVisibleChange(!popupControl.value);
          }}
        >
          <motion.div
            className='aspect-square overflow-hidden rounded-xl transition duration-300 size-11'
            animate={{
              translateX: mainContentVisible ? '0' : '148px',
            }}
            transition={{
              duration: 0.01,
            }}
          >
            {profile?.avatar ? (
              <img alt='logo' className='object-cover size-full' src={profile?.avatar} />
            ) : (
              <AvatarIcon className='size-11' />
            )}
          </motion.div>

          <motion.div
            className={cn('flex flex-col items-start transition duration-150', { 'opacity-0': !mainContentVisible })}
          >
            <span className='break-all text-start text-xs line-clamp-1'>
              {profile?.name} {profile?.last_name}
            </span>
            <span className='break-all text-start text-xs line-clamp-1'>{profile?.email}</span>
          </motion.div>

          <motion.button className={cn('transition duration-150', { 'opacity-0': !mainContentVisible })}>
            <CustomIcon className='size-2.5' name='anchor-brick' />
          </motion.button>
        </button>
      </div>

      <AnimatePresence>
        {popupControl.value && (
          <motion.div {...animations.fade} className='absolute inset-x-0 top-full translate-y-2'>
            <div className='rounded-3xl bg-white text-sm text-black shadow-md'>
              <div className={cn('font-medium text-[#7A7F85]')}>
                <div className='flex h-full items-center gap-2.5 break-all border-b border-border px-3 py-4 text-text'>
                  <div className='text-xs'>
                    <p>ID: {profile?.id || ''}</p>
                    <p>
                      {profile?.last_name || ''} {profile?.name || ''} {profile?.middle_name || ''}
                    </p>

                    <p className='text-gray '>{profile?.email || ''}</p>
                  </div>
                </div>

                <PermissionWrap grants={['profile', 'view']}>
                  <Link
                    className='flex items-center gap-2.5 border-b border-border py-3 px-4 text-xs hover:bg-bg/40'
                    to={`/profile/${profile?.id}`}
                  >
                    <CustomIcon className='h-4 w-4 text-gray' name='sr-user' />
                    Профиль
                  </Link>
                </PermissionWrap>

                <PermissionWrap grants={['telegram', 'index']}>
                  <Link
                    className='flex items-center gap-2.5 border-b border-border py-3 px-4 text-xs hover:bg-bg/40'
                    to='/telegram'
                  >
                    <CustomIcon className='h-4 w-4' name='telegram' />
                    Telegram
                  </Link>
                </PermissionWrap>

                <button
                  className='flex w-full items-center gap-2.5 py-3 px-4 text-left text-xs hover:bg-bg/40'
                  onClick={handleLogout}
                >
                  <CustomIcon className='h-4 w-4' name='br-power' />
                  Выйти
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
